<template>
  <v-main>
    <v-card flat max-width="740" class="px-5 mx-auto">
      <v-card-title class="text-h4 font-weight-bold">{{
        $t("settings.export.label")
      }}</v-card-title>
      <div class="text-body-1 pa-4">
        <i18n path="settings.export.p[0]" tag="p" class="mb-7">
          <template #company>{{ company }}</template>
        </i18n>

        <i18n path="settings.export.p[1]" tag="p" class="mb-7">
          <template #email>
            <span class="font-weight-medium">{{ email }}</span>
          </template>

          <template #settings>
            <router-link :to="{ name: 'Settings' }">
              {{ $t("settings.label") }}</router-link
            >
          </template>
        </i18n>

        <i18n path="settings.export.p[2]" tag="p" class="mb-7" />
      </div>

      <v-card-actions>
        <SectionButton
          :disabled="disable || sent"
          class="primary--text"
          @click="exportData"
        >
          <span class="pa-2">{{ $t("settings.export.action") }}</span>
        </SectionButton>
      </v-card-actions>

      <div class="text-body-1 pa-4" id="received" v-if="sent">
        <p class="grey--text lighten-1--text">
          {{ $t("settings.export.received") }}
        </p>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import SectionButton from "@/components/settings/utils/SectionButton";

export default {
  name: "Export",
  components: {
    SectionButton,
  },
  data: () => ({
    disable: false,
    sent: false,
    ref: null,
  }),
  computed: {
    ...mapGetters({
      email: "user/email",
      company: "config/company",
    }),
  },
  methods: {
    async exportData() {
      const now = new Date();
      this.disable = true;
      try {
        await this.$firebase.addDoc(this.ref, {
          recipient: this.email,
          created: now,
          updated: now,
        });
        this.sent = true;
      } catch (err) {
        this.disable = false;
        throw err;
      }
    },
  },
  created() {
    const baseRef = this.$route.matched.find((r) => r.meta.ref).meta.ref();
    this.ref = this.$firebase.collection(baseRef, "exportHistory");
  },
};
</script>
